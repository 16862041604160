<template>
  <div>
    <canvas id="qrCanvas" ref="qrcodeCanvas"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    image: {
      type: String,
    }
  },
  watch: {
    // 监听 变化，并更新
    url(newUrl) {
      this.generateQRCode(newUrl)
    }
  },
  mounted() {
    this.generateQRCode(this.url)
  },
  methods: {
    generateQRCode(url) {
      QRCode.toCanvas(this.$refs.qrcodeCanvas, url)
        .then(() => {
          // console.log('QR Code generated successfully')
          // 加载微信Logo图片
          if(this.image){
            const img = new Image();
            img.onload = () => {
              // 获取canvas的上下文
              const ctx = this.$refs.qrcodeCanvas.getContext('2d');
              // 设置Logo的位置（这里只是示例，您可以根据需要调整）
              const logoX = this.$refs.qrcodeCanvas.width - img.width - 62;
              const logoY = this.$refs.qrcodeCanvas.height - img.height - 62;
              // 绘制Logo
              ctx.drawImage(img, logoX, logoY, img.width, img.height);
            };
            // 设置Logo图片的源（请替换为您的微信Logo URL）
            img.src = this.image // 请确保URL是有效的
          }
        })
        .catch(error => {
          // console.error('Error generating QR Code:', error)
        })
    }
  }
}
</script>

<style lang="less" scoped>
  #qrCanvas{
    width: 120px !important;
    height: 120px !important;
  }
</style>
